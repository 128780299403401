import roleServices from '../../../../services/role'
import orgServices from '../../../../services/notisphere/organization'
import { getUserType,getAccessToken ,getRefreshToken} from '../../../../misc/auth'
import _ from 'lodash'
import { DISABLEDPERMISSIONS } from '../../../../misc/commons'
export default {
    data() {
        return {
            item: {
                id: null,
                name: null,
                permissions: [],
                org_name: null,
                org_id: null,
            },
            formType: 'INSERT',
            permissionOptions: [],
            disabledPermission: DISABLEDPERMISSIONS,

            organizations: [],
            selected_org: null
        }
    },
    computed: {
        form() {
            if (this.formType == 'INSERT') {
                return {
                    title: 'New Role'
                }
            }
            if (this.formType == 'UPDATE') {
                return {
                    title: 'Update Role'
                }
            }
            if (this.formType == 'VIEW') {
                return {
                    title: 'View Role'
                }
            }
        },
        groupedPermissionOptions() {
            var arr = this.permissionOptions.map(x => {
                return {
                    ...x,
                    checked: this.item.permissions.includes(x.id)  ? true : false,
                }
            })
            return _.chain(arr)
                .groupBy('group')
                .map((value, key) => ({ group: key, permissions: value }))
                .value()
        }
    },
    watch: {
        selected_org(val) {
            if (this.formType == 'INSERT' && this._isNotisphere) {
                this.getPermissionMeta(val ? val.type : null)
            }
            this.item.permissions = []
        },
    },
    methods: {
        forceRerenderInsert(selected_org) {
            this.formType = 'INSERT'
            this.$refs.modal.show()
            this.selected_org = selected_org
            if (this._isNotisphere) {
                this.fetchOrgMeta()
            } else {
                this.getPermissionMeta(this.$store.state.userType)
            }
        },
        forceRerenderUpdate(id, org_type, formType) {
            var userType = getUserType()
            this.formType = formType
            this.getRole(id)
            this.getPermissionMeta(org_type)
        },
        async save() {
            var isValid = await this.$refs['observer'].validate()
            if (isValid && this.formType == 'INSERT') {
                this.createRole()
            }
            if (isValid && this.formType == 'UPDATE') {
                this.updateRole()
            }
        },
        updateRole() {
            roleServices.updateRole(this.item).then(resp => {
                if (!resp.error) {
                    this.$emit('onCompleted', 'UPDATE')
                    this.$refs.modal.hide()
                }
            })
        },
        createRole() {
            roleServices.createRole({
                ...this.item,
                org_id: this.selected_org ? this.selected_org.id : null
            }).then(resp => {
                if (!resp.error) {
                    this.$emit('onCompleted', 'INSERT')
                    this.$refs.modal.hide()
                }
            })
        },
        getRole(id) {
            roleServices.getRole(id).then(resp => {
                if (!resp.error) {
                    this.item = resp.data.d
                    if((getUserType() == 'PROVIDER'|| getUserType() == 'NOTISPHERE'))
                    {
                        var disabledPermissions = []
                        disabledPermissions = this.permissionOptions.map(x => {
                            return {
                                ...x,
                                isDisabled: this.item.isNonVerified && this.disabledPermission.includes(x.id) ? true : false,
                            }
                        })
                        this.permissionOptions = disabledPermissions
                    }
                    if ((getUserType() == 'SUPPLIER' || getUserType() == 'NOTISPHERE') && (this.item.name == 'Administrator' || this.item.name == 'Recall Coordinator')) {
                        var that = []
                        that = this.permissionOptions.filter(i => {
                            return i.id !== 'Supplier_ViewDisruption'
                        })
                        this.permissionOptions = that
                    }
                    this.$nextTick(() => {
                        this.$refs.modal.show()
                    })
                }
            })
        },
        getPermissionMeta(org_type) {
            roleServices.getPermissionMeta(org_type).then(resp => {
                if (!resp.error) {
                    this.permissionOptions = resp.data.d
                    if ((getUserType() == 'SUPPLIER' || getUserType() == 'NOTISPHERE') && (this.item.name == 'Administrator' || this.item.name == 'Recall Coordinator')) {
                        var that = []
                        that = this.permissionOptions.filter(i => {
                            return i.id !== 'Supplier_ManageDisruption'
                        })
                        this.permissionOptions = that
                    }

                }
            })
        },
        togglePermission(permission_id) {
            if (this.item.permissions.includes(permission_id)) {
                var idx = this.item.permissions.indexOf(permission_id)
                this.item.permissions.splice(idx, 1)
            } else {
                this.item.permissions.push(permission_id)
            }
        },
        fetchOrgMeta() {
            orgServices.findMeta().then(resp => {
                if (!resp.error) {
                    this.organizations = resp.data.d
                }
            })
        }
    }
}